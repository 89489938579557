let config = {
  emailjs: {
    serviceID: "gmail_service_id",
    templateID: "gmail_template_id",
    userID: "zLs1l1ZoP2RuwcuXY"
  }
};



export default config;
